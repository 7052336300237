const EmployeeExportColumnService = {
  /**
   * Generate employee export column from leaves.
   *
   * @param leaves
   * @returns {*[]}
   */
  generateLeaveCols(leaves) {
    let cols = [];
    leaves.forEach((e) => {
      console.log(e);
      cols.push({
        key: `leaves.${e.leave_id}.count`,
        label: e.name
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase())
          .join(""),
        formatter: (value) => {
          return value ?? 0;
        },
      });
    });

    console.log("Cols", cols);
    return cols;
  },

  /**
   * Generate away columns
   *
   * @param aways
   * @returns {*[]}
   */
  generateAwayCols(aways) {
    let cols = [];
    aways.forEach((e) => {
      console.log(e);
      cols.push({
        key: `aways.${e.away_id}.count`,
        label: e.abbreviation,
        formatter: (value) => {
          return value ?? 0;
        },
      });
    });

    console.log("Cols", cols);
    return cols;
  },
};

export default EmployeeExportColumnService;
