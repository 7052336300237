<template>
  <b-table-simple :responsive="true" :bordered="bordered">
    <!--  Head  -->
    <b-thead>
      <b-tr>
        <b-th colspan="2">Leaves</b-th>
      </b-tr>
    </b-thead>
    <!-- end of head    -->
    <b-tbody>
      <b-tr v-for="leave in leaves" v-bind:key="leave.id">
        <b-td>
          <strong>{{
            leave.name
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase())
              .join("")
          }}</strong>
        </b-td>
        <b-td> {{ leave.name }} </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
export default {
  name: "LeaveSectionExportLegend",
  props: {
    leaves: {
      type: Array,
      required: true,
    },
    bordered: {
      required: false,
      default: true,
    },
  },
};
</script>

<style scoped></style>
