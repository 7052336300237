<template>
  <b-table
    table-class="table-head-custom table-vertical-center"
    show-empty
    :fields="fields"
    :items="items"
    :busy="isBusy"
    hover
    :responsive="true"
  >
    <template #thead-top>
      <b-th> </b-th>
      <b-th> </b-th>
      <b-th colspan="3"> </b-th>
      <b-th v-if="aways.length" :colspan="aways.length"> Aways </b-th>
      <b-th v-if="leaves.length" :colspan="leaves.length"> Leaves </b-th>
      <b-th colspan="1"> Off </b-th>
      <b-th colspan="1"> ALL </b-th>
    </template>

    <template v-slot:head(index)="data">
      <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
    </template>

    <template v-slot:cell(index)="data">
      <span class="font-weight-bolder">
        {{ data.index + 1 }}
      </span>
    </template>

    <template v-slot:cell(employee.offdays_count)="data">
      <span> {{ data.item.employee.offdays_count }}</span>
    </template>

    <template v-slot:cell(total.current)="data">
      <span>
        {{ `${data.item.total.current} / ${data.item.total.total}` }}</span
      >
    </template>

    <template v-slot:cell(employee)="data">
      <CommonEmployeeSummary :item="data.item.employee"></CommonEmployeeSummary>
    </template>

    <template #table-busy>
      <AppLoader></AppLoader>
    </template>
  </b-table>
</template>

<script>
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
export default {
  name: "EmployeeAttendanceExportTable",
  components: { AppLoader, CommonEmployeeSummary },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    leaves: {
      type: Array,
    },
    aways: {
      type: Array,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
