<template>
  <div :class="computeClass">
    <WorkingSectionExportLegend class="mr-4"></WorkingSectionExportLegend>

    <AwaySectionExportLegend
      class="mr-4"
      :aways="aways"
    ></AwaySectionExportLegend>

    <LeaveSectionExportLegend
      class="mr-4"
      :leaves="leaves"
    ></LeaveSectionExportLegend>

    <TotalSectionExportLegend></TotalSectionExportLegend>
  </div>
</template>

<script>
import AwaySectionExportLegend from "@/modules/company/components/shared/export/AwaySectionExportLegend";
import LeaveSectionExportLegend from "@/modules/company/components/shared/export/LeaveSectionExportLegend";
import TotalSectionExportLegend from "@/modules/company/components/shared/export/TotalSectionExportLegend";
import WorkingSectionExportLegend from "@/modules/company/components/shared/export/WorkingSectionExportLegend";

export default {
  name: "EmployeeExportLegend",
  components: {
    WorkingSectionExportLegend,
    TotalSectionExportLegend,
    LeaveSectionExportLegend,
    AwaySectionExportLegend,
  },
  props: {
    leaves: {
      type: Array,
      required: true,
    },
    aways: {
      type: Array,
      required: true,
    },
    isRow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initialClass: "d-flex flex-column flex-grow-1",
    };
  },
  computed: {
    /**
     * Determine the appropriate classes using isRow prop.
     *
     * This helps to enhance responsiveness of export legend component.
     *
     * @returns {string}
     */
    computeClass() {
      if (this.isRow) {
        return "d-flex flex-column flex-grow-1 flex-md-row flex-grow-1";
      }

      return this.initialClass;
    },
  },
};
</script>

<style scoped></style>
