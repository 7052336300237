<template>
  <b-button
    variant="primary"
    class="btn btn-icon btn-light btn-hover-primary mr-3"
    v-on:click="$emit('refresh-clicked', $event)"
    v-b-tooltip.hover="$t('EMPLOYEE.HOVER.REFRESH')"
  >
    <span class="svg-icon svg-icon-2x svg-icon-primary">
      <inline-svg src="/media/svg/icons/Media/Repeat.svg" />

    </span>
  </b-button>
</template>

<script>
export default {
  name: "AppRefreshButton",
  prop: {
    isBusy: {
      required: true,
      default: false,
    },
  },
};
</script>

<style scoped></style>
